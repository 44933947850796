import React from "react";
import { Link, graphql } from "gatsby";

import Blog from './';
export default (props) => (<Blog
  categories={
    <div>
      <h4 className="font18 uppercase">Kategoriat</h4>
      <Link to="/fi/myynti" className="font20 blue line_height40">Myynti</Link>
      <br/>
      <Link to="/fi/tarjouksen-tekeminen" className="font20 blue line_height40">Tarjouksen tekeminen</Link>
      <br/>
      <Link to="/fi/yrittajyys" className="font20 blue line_height40">Yrittäjyys</Link>
      <br/>
      <Link to="/fi/referenssitarinat" className="font20 blue line_height40">Referenssitarinat</Link>
    </div>
  }
  {...props}
/>);

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(
      sort: {fields: [frontmatter___date], order: DESC}, 
      filter: {fields: {language: {eq: "fi"}}, frontmatter: { date: { ne: null } } }
      ) {
      edges {
        node {
          excerpt
          fields {
            slug
            path
          }
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            title
            description
            meta {
              name
              content
            }
            link {
              rel
              href
              hreflang
            }
            hero
          }
        }
      }
    }
  }
`
